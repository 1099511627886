import { env } from '../../../env';
import { getItemFromCookies } from '../../../helpers/cookies-helper';

const clientId = env.NEXT_PUBLIC_SITE_CLIENT_ID;
const redirectUri = env.NEXT_PUBLIC_SITE_REDIRECT_URI;
const authEndpoint = env.NEXT_PUBLIC_SITE_AUTH_ENDPOINT;
const logoutEndpoint = env.NEXT_PUBLIC_SITE_LOGOUT_ENDPOINT;
const scope = env.NEXT_PUBLIC_SITE_SCOPE;
const responseType = env.NEXT_PUBLIC_SITE_RESPONSE_TYPE;

const generateState = () => {
  return Math.random().toString(36).slice(2);
};

// Initiate OAuth Flow with Axios
export function initiateOAuthFlow() {
  try {
    const state = generateState();
    sessionStorage.setItem('oauth_state', state);

    // Construct the OAuth authorization URL
    const params = new URLSearchParams();
    params.append('client_id', clientId);
    params.append('redirect_uri', redirectUri);
    params.append('response_type', responseType);
    params.append('scope', scope);
    params.append('state', state);

    const authUrl = `${authEndpoint}?${params.toString()}`;

    // Redirect user to Hydra for authentication
    globalThis.location.href = authUrl;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error initiating OAuth flow:', error);
  }
}

export function initiateLogout() {
  const refreshToken = getItemFromCookies('refreshToken');
  if (!refreshToken) {
    // eslint-disable-next-line no-console
    console.error('Refresh token not found');
    //   navigate('/login');
    return;
  }

  try {
    const logoutUrl = logoutEndpoint;
    globalThis.location.href = logoutUrl;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    //   navigate('/login');
  }
}
