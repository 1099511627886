'use client';

import React, { useState, useEffect } from 'react';
import { Alert, Checkbox, Input } from '@ui/components';
import AirportLogin from '../../../public/assets/AirportLogin.png';
import Form from 'next/form';
import {
  generateKey,
  encryptData,
  decryptData,
} from '../../../urql-client/utils/crypto';
import { z } from 'zod';
import { useSearchParams } from 'next/navigation';
import Button from '@ui/components/ui/button/Button';
import { Icon } from '@icons/icons';
import { useSignInMutation } from '../../../graphql/generated/graphql';
import { initiateOAuthFlow } from '../helper/hydra-initialisation';
import Link from 'next/link';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [challenge, setChallenge] = useState<string | null>();
  const searchParams = useSearchParams();
  const errorFromParams = searchParams.get('error');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [{ fetching: fetchingSignIn, error: fetchError }, signInMutation] =
    useSignInMutation();

  useEffect(() => {
    if (errorFromParams) {
      // Decode in case of special characters
      // TODO: Change this from backend error message
      setError(decodeURIComponent(errorFromParams));
    }
  }, [errorFromParams]);

  useEffect(() => {
    async function loadEmail() {
      const storedEncryptedEmail = localStorage.getItem('rememberedEmail');
      const storedKey = sessionStorage.getItem('emailKey');

      if (storedEncryptedEmail && storedKey) {
        const key = await crypto.subtle.importKey(
          'jwk',
          JSON.parse(storedKey),
          { name: 'AES-GCM' },
          false,
          ['decrypt']
        );
        const decryptedEmail = await decryptData(key, storedEncryptedEmail);
        setEmail(decryptedEmail);
        setRememberMe(true);
      }
    }

    loadEmail();
  }, []);

  useEffect(() => {
    const loginChallenge = searchParams?.get('login_challenge');
    if (loginChallenge) {
      setChallenge(loginChallenge);
    } else {
      initiateOAuthFlow();
    }
  }, [searchParams]);

  const handleLogin = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    setError(undefined);

    if (!challenge) {
      return;
    }
    try {
      const { data } = await signInMutation({
        userToSign: {
          email,
          password,
          challenge,
        },
      });

      if (fetchError) {
        setError(fetchError.message || 'Login failed.');
        return;
      }
      if (data?.signIn?.redirect_to) {
        if (rememberMe) {
          const key = await generateKey();
          const encryptedEmail = await encryptData(key, email);
          localStorage.setItem('rememberedEmail', encryptedEmail);

          sessionStorage.setItem(
            'emailKey',
            JSON.stringify(await crypto.subtle.exportKey('jwk', key))
          );
        } else {
          localStorage.removeItem('rememberedEmail');
          sessionStorage.removeItem('emailKey');
        }

        globalThis.location.href = data.signIn.redirect_to;
      } else {
        setError('No redirect URL provided by the server.');
      }
    } catch (error_) {
      // display error message from email and password validation
      if (error_ instanceof z.ZodError) {
        setError(error_.errors.map(err => err.message).join(' '));
      } else if (error_ instanceof Error) {
        setError(error_.message);
      } else {
        setError('An unexpected error occurred.');
      }
    }
  };

  return (
    <div className="flex w-full h-screen">
      {/* Left side Form */}
      <div className="flex justify-center items-center w-full lg:w-1/2 p-8 bg-white">
        <Form action="#" onSubmit={handleLogin} className="w-full max-w-md">
          {/* Error Alert */}
          {(error || fetchError) && (
            <Alert variant="error" title="Error">
              {error || fetchError?.message}
            </Alert>
          )}

          <h1 className="text-center text-3xl font-semibold mb-4">Login</h1>
          <p className="text-center text-gray-500 mb-6">
            Enter your email below to login to your account
          </p>

          <div className="space-y-4">
            {/* Email Field */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-900 pb-1"
              >
                Email
              </label>
              <Input
                id="email"
                placeholder="m@example.com"
                type="email"
                variant="roundedWhite"
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
            </div>

            {/* Password Field with "Forgot Password?" Link */}
            <div className="pb-4">
              <div className="flex justify-between items-center pb-1">
                <label
                  htmlFor="password"
                  className="text-sm font-medium text-gray-900"
                >
                  Password
                </label>
                <Link href="#">
                  <span className="underline pl-1 text-sm">
                    Forgot your password?
                  </span>
                </Link>
              </div>
              <Input
                id="password"
                placeholder="Password"
                variant="roundedWhite"
                type="password"
                value={password}
                onChange={event => setPassword(event.target.value)}
              />
            </div>
          </div>

          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="rememberMe"
                checked={rememberMe}
                onCheckedChange={checked => setRememberMe(checked === true)}
              />
              <label
                htmlFor="rememberMe"
                className="text-sm font-medium leading-none"
              >
                Remember me
              </label>
            </div>
          </div>

          <div className="space-y-3">
            <Button
              type="submit"
              variant="dark"
              radius="normal"
              size="large"
              loading={fetchingSignIn}
              fullWidth
            >
              Login
            </Button>

            <Button variant="secondary" size="large" radius="normal" fullWidth>
              Login with Google
            </Button>
          </div>

          <p className="text-center text-sm mt-8">
            Don&apos;t have an account?
            <Link href="#">
              <span className="underline pl-1">Sign Up</span>
            </Link>
          </p>
        </Form>
      </div>

      {/* Right side: Image */}
      <div
        className="md:w-1/2 md:block hidden h-screen bg-cover bg-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.3)), url(${AirportLogin.src})`,
        }}
      >
        <div className="m-8">
          <Icon name="RooshIconWhite" />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
