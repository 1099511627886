// utils/crypto.ts

export async function generateKey(): Promise<CryptoKey> {
  return await crypto.subtle.generateKey(
    {
      name: 'AES-GCM',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt']
  );
}

export async function encryptData(key: CryptoKey, data: string): Promise<string> {
  const encoder = new TextEncoder();
  const encodedData = encoder.encode(data);

  const iv = crypto.getRandomValues(new Uint8Array(12));

  const encryptedData = await crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv: iv,
    },
    key,
    encodedData
  );

  return JSON.stringify({
    iv: [...iv],
    data: [...new Uint8Array(encryptedData)],
  });
}

export async function decryptData(
  key: CryptoKey,
  encrypted: string
): Promise<string> {
  const { iv, data } = JSON.parse(encrypted);
  const ivArray = new Uint8Array(iv);
  const encryptedArray = new Uint8Array(data);

  const decryptedData = await crypto.subtle.decrypt(
    {
      name: 'AES-GCM',
      iv: ivArray,
    },
    key,
    encryptedArray
  );

  const decoder = new TextDecoder();
  return decoder.decode(decryptedData);
}
